@font-face {
	font-family: 'UbuntuRegular';
	src: local('RobotoRegular'), url('./assets/fonts/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'UbuntuLight';
	src: local('UbuntuLight'), url('./assets/fonts/Ubuntu-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'UbuntuBold';
	src: local('UbuntuBold'), url('./assets/fonts/Ubuntu-Bold.ttf') format('truetype');
}

html, body {
	padding: 0px;
	margin: 0px;
	background-color: white;
}

.c-white {
	color: white;	
}

.u-regular {
  	font-family: "UbuntuRegular" !important; 
}

.u-light {
  	font-family: "UbuntuLight" !important; 
}

.u-bold {
	font-family: "UbuntuBold" !important; 
}

.f-18 {
  	font-size: 18px;
}

.f-20 {
	font-size: 20px;
}

.f-25 {
	font-size: 25px;
}

.f-40 {
	font-size: 40px;
}

.f-45 {
	font-size: 45px;
}

.f-75 {
	font-size: 75px;
}


h1 {
	margin-bottom: 200px !important;
}


.footer {
	background-color: #102541;
	height: 40px;
	color: white;
	padding-top: 10px;
}

.icon-footer {
	font-size: 20px;
	color: white;
	margin-left: 20px;
}

.bg-carousel {
	/* background-position: center center;
  	background-repeat: no-repeat;
  	background-size: 100% 100%; */
	background: none;
	min-height:600px;
	width: 100% !important;
}

.pads {
	padding: 0;
}

.down-btn button, .down-btn button:hover, .down-btn button:focus{
	background-color: #102541;
	border: none;
	padding: 0;
	padding-right: 5px;
	padding-left: 5px;
}

.btn-success.focus, .btn-success:focus{
	background:#102541 !important; 
	border: none !important;
	box-shadow: none !important;
}

btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus {
    box-shadow: none !important;
}

.carousel-caption {
	left: 0% !important;
	right: 0% !important;
}
  
.carousel-item {
	height: 600px;
	background: no-repeat center center scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  }
  
.portfolio-item {
	margin-bottom: 30px;
}

.div-icons {
	display: inline-block;
	width: 50%;
}

.div-left {
	text-align: left;	
}

.div-right {
	text-align: right;
	padding-right: 40px;
}

.border-social {
	width: 200px;
	border-bottom: 2px solid white;
}

.mal40 {
	margin-left: 40px;
}

.card-cumtom {
	height: 300px;
	border-color: transparent !important;
}

.cbody {
	justify-content: center !important;
	align-content: center;
}

.body-inner {
	height: 300px;
    display: grid;
    flex: 1;
    justify-content: center;
    align-content: center;
}

.color-title {
	color: #707070;
}

.btn-active {
	min-width: 248px;
    min-height: 80px;
    border-radius: 20px !important;
	background-color: #102541 !important;
	color: white !important;
	border-color: transparent !important;
}

.btn-normal {
	min-width: 248px;
    min-height: 80px;
    border-radius: 0 !important;
    border-color: transparent;
    background-color: transparent !important;
	color: #707070 !important;
	border-color: transparent !important;
}

.card-service {
	width: 80%;
	background-color: white;
	border-radius: 39px;
	height: auto;
	display: flex;
	flex-direction: row;
	margin-left: auto;
    margin-right: auto;
}

.card-service-m {
	width: 80%;
	background-color: white;
	border-radius: 39px;
	height: auto;
	margin-left: auto;
    margin-right: auto;
}

.ser-div-left {
	width: 45%;
	display: grid;
    align-items: center;
	justify-content: center;
	padding: 5px 20px 5px 20px;
}

.ser-div-right {
	width: 55%;
	display: grid;
    align-items: center;
	justify-content: center;
	padding: 20px;
}

.title-card {
	line-height: initial;
    margin-bottom: -200px;
}

.img-card {
	border-radius: 39px;
}

.project1 {
	max-height: 260px;
	height: 260px;
}

.hp1 {
	position: absolute;
    top: 40%;
    left: 30%;
    right: auto;
    color: white;
}

.img-contact {
	background-image: url(./assets/img/contact.png);
	background-repeat: no-repeat;
	background-size: cover;
	min-height:700px;
}

.title-contact {
	text-align: center;
	color: white;
}

.card-form {
	width: 70%;
	height: auto;
	padding-left: 100px;
	padding-right: 100px;
	padding-bottom: 50px;
	padding-top: 50px;
	background-color: white;
	border-radius: 20px;
	margin-left: auto;
	margin-right: auto;
}

.form-group {
    margin-bottom: 10px
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -10px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.form-control {
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 1px solid #61615e;
    background: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	border-radius: 0px !important;
}

button.btn-blue  {
    display: block;
    padding: 11px 32px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.btn-blue {
    background-color: #102541 !important;
    color: #fff !important;
	margin-right: auto;
	margin-left: auto;
	border-radius: 33px !important;
	width: 200px;
}

.dot-active {
	color: #005CDB;
}

.dot-normal {
	color: #C5C3C3;
}

.img-resp {
	border-radius: 20px;
}

.select-custom {
	background-color: transparent !important;
    border: none !important;
    width: auto !important;
    margin-left: auto !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
	height: 25px !important;
	color: white !important;
}

.div-select {
	width: 73px;
    margin-left: auto;
    background-color: #102541;
    border-radius: 20px;
    padding-right: 15px;
    padding-top: 0px;
}


select {
text-shadow:0 1px 0 rgba(0,0,0,0.4);
}

@media (max-width: 576px) {
	.f-25 {
		font-size: 20px !important;
	}
	.f-20 {
		font-size: 18px !important;
	}
	.f-40 {
		font-size: 18px !important;
	}
	.f-45 {
		font-size: 20px !important;
	}
	.f-75 {
		font-size: 40px !important;
	}
	.card-cumtom {
		height: 220px !important;
	}
	.body-inner {
		height: 220px !important;
	}
	.hp1 {
		top: 45%;
		left: 38%;
	}
	.card-form {
		width: 100% !important;
		padding-left: 10px !important;
    	padding-right: 10px !important;
	}
}







html {
	box-sizing: border-box;
  }
  
  *, *:before, *:after {
	box-sizing: inherit;
  }
  
  .carousel-custom {
	min-width: 900px;
	max-width: 1236px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 48px;
	padding-right: 48px;
	position: relative;
  }
  
  .carousel__activator {
	display: none;
  }
  
  .carousel__controls {
	display: none;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	top: 0;
	right: 16px;
	left: 16px;
	bottom: 0;
  }
  .carousel__controls:first-of-type {
	justify-content: flex-end;
  }
  .carousel__controls:last-of-type {
	justify-content: flex-start;
  }
  
  .carousel__control {
	cursor: pointer;
	display: flex;
	align-items: center;
	
	color: #3d414a;
	border-radius: 100%;
	box-shadow: 0 2px 10px 0 rgba(33, 34, 36, 0.3);
	font-size: 24px;
	height: 48px;
	justify-content: center;
	transition: 0.3s all;
	width: 48px;
	z-index: 1;
  }
  .carousel__control:hover {
	-webkit-transform: scale(1.05);
			transform: scale(1.05);
  }
  
  .carousel__activator:nth-of-type(1):checked
  ~ .carousel__controls:nth-of-type(1) {
	display: flex;
  }
  
  .carousel__activator:nth-of-type(1):checked
  ~ .carousel__screen
  .carousel__track {
	-webkit-transform: translateX(0%);
			transform: translateX(0%);
  }
  
  .carousel__activator:nth-of-type(2):checked
  ~ .carousel__controls:nth-of-type(2) {
	display: flex;
  }
  
  .carousel__activator:nth-of-type(2):checked
  ~ .carousel__screen
  .carousel__track {
	-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
  }
  
  .carousel__activator:nth-of-type(3):checked
  ~ .carousel__controls:nth-of-type(3) {
	display: flex;
  }
  
  .carousel__activator:nth-of-type(3):checked
  ~ .carousel__screen
  .carousel__track {
	-webkit-transform: translateX(-200%);
			transform: translateX(-200%);
  }
  
  .carousel__activator:nth-of-type(4):checked
  ~ .carousel__controls:nth-of-type(4) {
	display: flex;
  }
  
  .carousel__activator:nth-of-type(4):checked
  ~ .carousel__screen
  .carousel__track {
	-webkit-transform: translateX(-300%);
			transform: translateX(-300%);
  }
  
  .carousel__activator:nth-of-type(5):checked
  ~ .carousel__controls:nth-of-type(5) {
	display: flex;
  }
  
  .carousel__activator:nth-of-type(5):checked
  ~ .carousel__screen
  .carousel__track {
	-webkit-transform: translateX(-400%);
			transform: translateX(-400%);
  }
  
  .carousel__activator:nth-of-type(6):checked
  ~ .carousel__controls:nth-of-type(6) {
	display: flex;
  }
  
  .carousel__activator:nth-of-type(6):checked
  ~ .carousel__screen
  .carousel__track {
	-webkit-transform: translateX(-500%);
			transform: translateX(-500%);
  }
  
  .carousel__activator:nth-of-type(7):checked
  ~ .carousel__controls:nth-of-type(7) {
	display: flex;
  }
  
  .carousel__activator:nth-of-type(7):checked
  ~ .carousel__screen
  .carousel__track {
	-webkit-transform: translateX(-600%);
			transform: translateX(-600%);
  }
  
  .carousel__activator:nth-of-type(8):checked
  ~ .carousel__controls:nth-of-type(8) {
	display: flex;
  }
  
  .carousel__activator:nth-of-type(8):checked
  ~ .carousel__screen
  .carousel__track {
	-webkit-transform: translateX(-700%);
			transform: translateX(-700%);
  }
  
  .carousel__activator:nth-of-type(9):checked
  ~ .carousel__controls:nth-of-type(9) {
	display: flex;
  }
  
  .carousel__activator:nth-of-type(9):checked
  ~ .carousel__screen
  .carousel__track {
	-webkit-transform: translateX(-800%);
			transform: translateX(-800%);
  }
  
  .carousel__activator:nth-of-type(10):checked
  ~ .carousel__controls:nth-of-type(10) {
	display: flex;
  }
  
  .carousel__activator:nth-of-type(10):checked
  ~ .carousel__screen
  .carousel__track {
	-webkit-transform: translateX(-900%);
			transform: translateX(-900%);
  }
  
  .carousel__screen {
	overflow: hidden;
	margin-left: -16px;
	margin-right: -16px;
  }
  
  .carousel__track {
	font-size: 0;
	transition: all 0.3s ease 0s;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
  }
  
  .carousel__item {
	display: inline-flex;
	padding-left: 16px;
	padding-right: 16px;
	vertical-align: top;
	white-space: normal;
  }
  
  .carousel__item--desktop-in-1 {
	width: 100%;
  }
  
  .carousel__item--desktop-in-2 {
	width: 50%;
  }
  
  .carousel__item--desktop-in-3 {
	width: 33.3333333333%;
  }
  
  .carousel__item--desktop-in-4 {
	width: 25%;
  }
  
  .carousel__item--desktop-in-5 {
	width: 20%;
  }
  
  
  
  .demo-content {
	color: #fff;
	border-radius: 3px;
	font-size: 12px;	
	height: auto;
	width: 100%;
	border-radius: 5px;
	border: 1px solid #F8F9F9;
  }

  .img-top {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
  }
  
  .carousel__item:nth-child(1) .demo-content {
	background-color: #216485;
  }
  
  .carousel__item:nth-child(2) .demo-content {
	background-color: #3692b6;
  }
  
  .carousel__item:nth-child(3) .demo-content {
	background-color: #6fccc9;
  }
  
  .carousel__item:nth-child(4) .demo-content {
	background-color: #a6e3cf;
  }
  
  .carousel__item:nth-child(5) .demo-content {
	background-color: #aff0be;
  }
  
  .carousel__item:nth-child(6) .demo-content {
	background-color: #527059;
  }
  
  .carousel__item:nth-child(7) .demo-content {
	background-color: #243127;
  }
  
  @media screen and (max-width: 1023px) {
	.carousel {
	  padding-left: 0;
	  padding-right: 0;
	}
  
	.carousel__activator:nth-of-type(n):checked ~ .carousel__controls:nth-of-type(n) {
	  display: none;
	}
	.carousel__activator:nth-of-type(n):checked ~ .carousel__screen .carousel__track {
	  -webkit-transform: none;
			  transform: none;
	}
  
	.carousel__screen {
	  margin-left: 0;
	  margin-right: 0;
	}
  
	.carousel__track {
	  overflow-x: auto;
	  width: auto;
	  padding-left: 48px;
	  padding-right: 48px;
	}
  
	.carousel__item--tablet-in-1 {
	  width: 90%;
	}
  
	.carousel__item--tablet-in-2 {
	  width: 45%;
	}
  
	.carousel__item--tablet-in-3 {
	  width: 30%;
	}
  }
  @media screen and (max-width: 650px) {
	.carousel__track {
	  padding-left: 0;
	  padding-right: 0;
	}
  
	.carousel__item--mobile-in-1 {
	  width: 90%;
	}
  
	.carousel__item--mobile-in-2 {
	  width: 45%;
	}
  
	.carousel__item--mobile-in-3 {
	  width: 30%;
	}
  }
  